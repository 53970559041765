import styled from "@emotion/styled";
import AmplitudeProvider from "src/AmplitudeProvider";
import TopBar from "./topbar";

import {
  useGetAccountDetails,
  useGetBillingOverview,
  useGetElitePerks,
} from "src/v2/api/hooks";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { classNames, Link, SidebarState, UIContext } from "@alpacahq/ui";
import { Box, Progress } from "@chakra-ui/react";
import { UIPage } from "../../../../src/local_libraries/@alpacahq:ui-deprecated";
import { isNotFullActiveAccount } from "../../../components/LayoutComponents/Menu/MenuLeft/menuData";
import { inSupportedLocation } from "../../../utils/crypto";
import {
  featureFlagManager,
  isFeatureAccessible,
  setFlag,
  useFlag,
} from "../../../v2/helpers/flags";
import { AuthService } from "../../api/auth";
import { PaperBanner } from "../../pages/dashboard/home/paperBanner";
import { Path } from "../../path";
import { isPaperOrOnboarding } from "../../utils";
import { Sidebar, SidebarProps } from "../Sidebar/Sidebar";
import { useAccountContext } from "src/v2/providers/AccountProvider";

// experimenting with larger screen size for some pages - ie `/trade/NVDA`
const WIDE_PAGE_MAX_WIDTH = "max-w-[1648px]";
const WIDE_PAGES = [
  "/trade/",
  "/account/orders",
  "/brokerage/dashboard/overview",
  "/paper/dashboard/overview",
];

export interface PageProps {
  children: React.ReactNode;
  noHeader?: boolean;
  disableAppMenu?: boolean;
  product: string;
}

const TEMPORARY_UI_PAGES = [
  {
    path: "/paper/dashboard/orders",
    title: "Orders",
    subtitle: "Your open and closed orders.",
  },
];

export const FUNDING_FEATURE_FLAG = "funding_feature_flag";

export const FUNDING_FEATURE_FLAG_PERCENTAGE = 100;

export const FDIC_SWEEP_FEATURE_FLAG = "fdic_sweep_feature_flag";

export const FDIC_SWEEP_FEATURE_FLAG_PERCENTAGE = 100;

export const heights: Record<string, string | any> = {
  sidebar: "70px",
  topbar: "91.6667px",
  apply: (value: string) =>
    value.replace(/{(.*?)}/g, (match, key) => heights[key]),
};

const Container = styled(Box)`
  .page--content {
    margin-left: auto;
    margin-right: auto;
  }

  .sticky {
    position: sticky;
    top: 0;
  }
`;

export const Page = (props: PageProps) => {
  const v2 = useFlag("uix-v2");

  const { sidebarState } = useContext(UIContext);

  const [product, setProduct] = useState(props.product);

  const isDebugMode =
    new URLSearchParams(window?.location?.search ?? "")?.get("debug") ?? null;

  if (isDebugMode !== null) {
    setFlag("debug", isDebugMode === "true");
  }

  featureFlagManager(FUNDING_FEATURE_FLAG, FUNDING_FEATURE_FLAG_PERCENTAGE);
  featureFlagManager(
    FDIC_SWEEP_FEATURE_FLAG,
    FDIC_SWEEP_FEATURE_FLAG_PERCENTAGE
  );

  const {
    currentAccount,
    liveAccounts,
    setProduct: setAccountContextProduct,
  } = useAccountContext();

  useEffect(() => {
    if (product === "dynamic") {
      const updatedProduct = window.localStorage.getItem("product") || "paper";
      setProduct(updatedProduct);
      setAccountContextProduct(updatedProduct);
    }
    setAccountContextProduct(product);
  }, [product]);

  const isPaper = product === "paper";
  const isWidePage = WIDE_PAGES.some((path) =>
    window?.location?.pathname?.startsWith(path)
  );

  // we are doing this until the pages are migrated to v2
  const temporaryUIPage = TEMPORARY_UI_PAGES.find((path) =>
    window.location.pathname.includes(path.path)
  );

  const { noHeader, children, disableAppMenu } = props;

  const liveAccount = liveAccounts?.[0];
  // These endpoints are using the live account to determine information
  const { billing } = useGetBillingOverview("billing", {
    enabled: !!liveAccount,
  });
  const { details } = useGetAccountDetails("details", liveAccount?.id, {
    enabled: !!currentAccount?.id,
  });
  const { perks } = useGetElitePerks("elite-perks", liveAccount?.id ?? "", {
    enabled: !!liveAccount?.id && !isPaperOrOnboarding(liveAccount),
  });

  const isSubscribed =
    (billing?.lifetimeSubCount || 0) > 0 || perks?.market_data;
  const cryptoTier = liveAccount?.crypto_tier ?? 0;

  // percentage of tier completion, given 8 tiers
  const cryptoTierProgress = (cryptoTier / 8) * 100;

  const canTransferCrypto =
    inSupportedLocation(details) && !isNotFullActiveAccount({ liveAccount });

  const onSwitchUIClick = useCallback(() => {
    setFlag("uix-v2", !v2);

    // send amplitude event
    AmplitudeProvider.dispatch("dropdown_switch_to_legacy_button_clicked");

    // refresh the page
    window.location.reload();
  }, [v2]);

  const sidebarProps: Pick<SidebarProps, "settings" | "items"> = useMemo(
    () => ({
      settings: [
        {
          label: "Profile",
          leftIcon: "Identification",
          href: Path.ROUTE_ALPACA_PROFILE,
        },
        {
          label: "Log Out",
          leftIcon: "LockClosed",
          onClick: AuthService.signOut,
        },
      ],
      items: [
        {
          label: "Home",
          leftIcon: "Home",
          href: Path.format(Path.ROUTE_DASHBOARD, { product }),
        },
        {
          label: "Account",
          leftIcon: "RectangleStack",
          subItems: [
            {
              label: "Positions",
              leftIcon: "ChartPie",
              href: Path.ROUTE_POSITIONS,
            },
            {
              label: "Orders",
              leftIcon: "ClipboardDocumentList",
              href: Path.format(Path.ROUTE_ORDERS, { product }),
            },
            {
              label: "Activities",
              leftIcon: "QueueList",
              href: Path.format(Path.ROUTE_ACCOUNT_ACTIVITIES, { product }),
            },
            {
              label: "Balances",
              leftIcon: "TableCells",
              href: Path.format(Path.ROUTE_BALANCES, { product }),
            },
            {
              label: "Configure",
              leftIcon: "AdjustmentsVertical",
              href: Path.ROUTE_CONFIGURATION,
            },
            !isPaper && {
              label: "Documents",
              leftIcon: "DocumentDuplicate",
              href: Path.ROUTE_DOCUMENTS,
            },
          ],
        },
        {
          label: "Alpaca Connect",
          leftIcon: "Cloud",
          href: Path.ROUTE_ALPACA_CONNECT,
        },
        {
          label: "Plans & Features",
          leftIcon: "Heart",
          href: Path.ROUTE_PLANS_AND_FEATURES,
          subItems: [
            !isPaper && {
              label: "Trading Plan",
              leftIcon: "ClipboardDocumentList",
              href: Path.ROUTE_ALPACA_TRADING_PLAN,
            },
            !details?.is_professional && {
              label: "Market Data",
              leftIcon: "PresentationChartLine",
              href: isSubscribed
                ? Path.ROUTE_USER_SUBSCRIPTION
                : Path.ROUTE_USER_SUBSCRIPTION_NEW,
            },
            !isPaper &&
              canTransferCrypto && {
                label: "Crypto Fee",
                leftIcon: "ChartBarSquare",
                href: Path.format(Path.ROUTE_ALPACA_PROFILE, {
                  tab: "crypto-fees",
                }),
              },
          ],
        },
        !isPaper && {
          label: `Funds${canTransferCrypto ? " & Wallet" : ""}`,
          leftIcon: "Banknotes",
          subItems: isFeatureAccessible(FUNDING_FEATURE_FLAG)
            ? [
                {
                  label: "Transfer",
                  leftIcon: "BuildingLibrary",
                  href: Path.format(Path.ROUTE_FUNDING, { product }),
                },
                {
                  label: "History",
                  leftIcon: "ArchiveBox",
                  href: Path.format(Path.ROUTE_FUNDING_HISTORY, { product }),
                },
                canTransferCrypto && {
                  label: "Crypto Transfers",
                  leftIcon: "CurrencyDollar",
                  href: Path.format(Path.ROUTE_CRYPTO_TRANSFERS, { product }),
                },
              ]
            : [
                {
                  label: "Banking",
                  leftIcon: "BuildingLibrary",
                  href: Path.format(Path.ROUTE_BANKING, { product }),
                },
                canTransferCrypto && {
                  label: "Crypto Transfers",
                  leftIcon: "CurrencyDollar",
                  href: Path.format(Path.ROUTE_CRYPTO_TRANSFERS, { product }),
                },
              ],
        },
        {
          label: "API",
          leftIcon: "CommandLine",
          href: Path.URL_DOCS,
          newTab: true,
        },
        {
          label: "Community",
          leftIcon: "ChatBubbleLeftRight",
          subItems: [
            {
              label: "Slack",
              leftIcon: "Hashtag",
              href: Path.URL_SLACK,
              newTab: true,
            },
            {
              label: "Forum",
              leftIcon: "Users",
              href: Path.URL_FORUM,
              newTab: true,
            },
          ],
        },
        {
          label: "Support",
          leftIcon: "Lifebuoy",
          href: Path.URL_SUPPORT,
          newTab: true,
        },
        {
          label: "Legal",
          leftIcon: "Scale",
          href: Path.URL_DISCLOSURES,
          newTab: true,
        },
      ],
    }),
    [
      ,
      canTransferCrypto,
      details,
      isNotFullActiveAccount,
      isPaper,
      isSubscribed,
      onSwitchUIClick,
      product,
      sidebarState,
    ]
  );

  useEffect(() => {
    // if defined pass account and details to AmplitudeProvider
    if (details && liveAccount) {
      AmplitudeProvider.setAccount({ liveAccount, details });
    }
  }, [liveAccount, details]);

  return (
    <>
      <Container>
        <Box h="100%" w="100%" display="flex" id="page-wrapper">
          {!disableAppMenu && sidebarState !== SidebarState.HIDDEN && (
            <Sidebar
              {...sidebarProps}
              ownerId={liveAccount?.id}
              product={product}
            >
              {sidebarState === SidebarState.EXPANDED &&
                !isPaper &&
                canTransferCrypto && (
                  <>
                    <div className="bg-white border border-b-3 border-warm-gray-200 rounded-md p-2.5">
                      <Link
                        className="w-full font-medium text-gray-800 dark:text-gray-200 rounded-md focus:border-yellow-50 focus:outline-none focus:ring-yellow-50"
                        to={Path.format(Path.ROUTE_ALPACA_PROFILE, {
                          tab: "crypto-fees",
                        })}
                      >
                        <div className="flex justify-between items-center mb-2">
                          Crypto Fee Rate
                          <label className="text-xs bg-gray-200 rounded p-1 dark:bg-blue-400 dark:text-gray-800">
                            Level&nbsp; {cryptoTier}
                          </label>
                        </div>
                        <Progress
                          bg="gray.50"
                          id="progress"
                          size="sm"
                          hasStripe
                          isAnimated
                          rounded="md"
                          value={cryptoTierProgress}
                        />
                      </Link>
                    </div>
                  </>
                )}
            </Sidebar>
          )}
          <Box
            h="100vh"
            marginLeft="auto"
            overflowY="scroll"
            flexGrow={1}
            className={classNames(
              "page--content",
              isWidePage ? WIDE_PAGE_MAX_WIDTH : "max-w-6xl"
            )}
          >
            {!noHeader &&
              // todo: temporary until subscription flow is migrated to v2
              !["agreement", "subscription", "subscribe"].some(
                (s) =>
                  window.location.href.includes(s) &&
                  // @todo temporary conflict with agreements profile tab & options opt-in agreement
                  !(
                    window.location.href.includes("agreements") ||
                    window.location.href.includes("options-agreement")
                  )
              ) && (
                <>
                  {product === "paper" && <PaperBanner account={liveAccount} />}
                  <TopBar product={product} />
                </>
              )}
            <Box>
              <Box id="__anchor" />
              {!!temporaryUIPage ? (
                <UIPage
                  title={temporaryUIPage.title}
                  subtitle={temporaryUIPage.subtitle}
                  banner={
                    product === "paper" && <PaperBanner account={liveAccount} />
                  }
                >
                  {children}
                </UIPage>
              ) : (
                children
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Page;
