import { timer } from "../entityOnboarding/timer";
import { Tier } from "./pages/tier";
import AmplitudeProvider from "src/AmplitudeProvider";
import { Path } from "src/v2/path";
import { OptionsApprovalStatus, Account } from "src/v2/api/rest/account";
import { isPaperOrOnboarding } from "src/selectors";
import moment from "moment";

export const isLocalStorageAvailable = () => {
  try {
    window.localStorage.setItem("_test_", "1");
    window.localStorage.removeItem("_test_");
    return true;
  } catch (_error) {
    return false;
  }
};

const TIER_LOCAL_STORAGE_KEY = "options_opt_in_tier";
const TIMER_LOCAL_STORAGE_KEY = "opt_in_timer";
const UTM_LOCAL_STORAGE_KEY = "options_opt_in_utms";

/** Local Storage Helper */
export const storage = {
  setUTMs: (utms: { [key: string]: string }) => {
    window.localStorage.setItem(UTM_LOCAL_STORAGE_KEY, JSON.stringify(utms));
  },
  getUTMs: (): { [ key: string ]: string } | undefined => {
    const utms = window.localStorage.getItem(UTM_LOCAL_STORAGE_KEY);

    if (!utms) {
      return;
    }

    return JSON.parse(utms) as { [key: string]: string };
  },
  removeUTMs: () => {
    window.localStorage.removeItem(UTM_LOCAL_STORAGE_KEY);
  },
  setTier: (tier: Tier) => {
    window.localStorage.setItem(TIER_LOCAL_STORAGE_KEY, tier);
  },
  getTier: (): Tier | null => {
    return window.localStorage.getItem(TIER_LOCAL_STORAGE_KEY) as Tier;
  },
  removeTier: () => {
    window.localStorage.removeItem(TIER_LOCAL_STORAGE_KEY);
  },
  startTimer: () => {
    if (!timer.isSet(TIMER_LOCAL_STORAGE_KEY)) {
      timer.start(TIMER_LOCAL_STORAGE_KEY);
    }
  },
  endTimer: () => {
    const duration = timer.end(TIMER_LOCAL_STORAGE_KEY);
    timer.remove(TIMER_LOCAL_STORAGE_KEY);
    return duration;
  },
};

export const logAmplitudeEvent = {
  started: () => {
    AmplitudeProvider.dispatch("algodash_options_opt_in_started");
  },
  completed: (duration?: number,  utms?: { [key: string]: string }) => {
    AmplitudeProvider.dispatch("algodash_options_opt_in_completed", {
      duration,
      ...utms,
    });
  },
  nextClicked: () => {
    AmplitudeProvider.dispatch("algodash_options_opt_in_next_clicked", {
      url: window.location.pathname,
    });
  },
  backClicked: () => {
    AmplitudeProvider.dispatch("algodash_options_opt_in_back_clicked", {
      url: window.location.pathname,
    });
  },
  pageCompleted: (duration?: number) => {
    AmplitudeProvider.dispatch("algodash_options_opt_in_page_completed", {
      duration,
      url: window.location.pathname,
    });
  },
};

export const BANKING_AND_ONBOARDING_PATHS = [
  Path.ROUTE_BANKING,
  Path.ROUTE_BANKING_DEPOSIT,
  Path.ROUTE_NEW_ACCOUNT,
  Path.ROUTE_ENTITY_ONBOARDING_PROFILE,
  Path.ROUTE_ENTITY_ONBOARDING_ACCOUNT_OPENER,
  Path.ROUTE_ENTITY_ONBOARDING_AUTH_INDIVIDUALS_UBOS,
  Path.ROUTE_ENTITY_ONBOARDING_DOCUMENTS,
];

export const isOptionsOnboardingAvailable = (
  account?: Account,
  optionsApprovalStatus?: OptionsApprovalStatus,
  options?: { allowLegalEntities?: boolean }
) => {
  if (!account || !optionsApprovalStatus) {
    return false;
  }

  // Do not allow user to onboard for options:
  // if user is paper only or onboarding
  // Or is legal entity and allowLegalEntities is false
  // Or is legal entity and options_approved_level is 3 (highest level)
  if (
    isPaperOrOnboarding({ account }) ||
    (account?.is_legal_entity && !options?.allowLegalEntities) || 
    (account?.is_legal_entity && account.options_approved_level === 3)
  ) {
    return false;
  }

  // If user's application is in pending status don't allow them to apply
  if (["PENDING"].includes(optionsApprovalStatus?.OptionsRequestStatus ?? "")) {
    return false;
  }

  // Don't allow the user to reapply if their reapply available date is after today
  if (
    optionsApprovalStatus?.ReapplyAvailableDate &&
    moment(optionsApprovalStatus?.ReapplyAvailableDate).isAfter(new Date())
  ) {
    return false;
  }

  return true;
};


export const getUTMsFromSearch = (search: string): { [key: string]: string } | undefined => {
  if (!search) {
    return;
  }

  const params = new URLSearchParams(search);

  const source = params.get("utm_source");
  const medium = params.get("utm_medium");
  const campaign = params.get("utm_campaign");
  const term = params.get("utm_term");
  const content = params.get("utm_content");

  const utms = {
    ...(source && { utm_source: source }),
    ...(medium && { utm_medium: medium }),
    ...(campaign && { utm_campaign: campaign }),
    ...(term && { utm_term: term }),
    ...(content && { utm_content: content }),
  }

  if (!Object.keys(utms).length) {
    return;
  }

  return utms;
}